import { mapState } from 'pinia'

import {
  SOLRESOR_FALLBACK_HERO_ID,
  ROLFS_FALLBACK_IMAGE_ID,
  SOLRESOR_FALLBACK_IMAGE,
  ROLFS_FALLBACK_IMAGE
} from '@/constants/images'

export const MAX_N_HOTEL_IMAGES = 30

/**
 * Hotel image size functions for the various providers used.
 */
const gptravelSizes = {
  thumbnail: 'thumbnails/400/',
  large: 'thumbnails/800/',
  xlarge: 'thumbnails/999/',
  og: 'thumbnails/800/',
  max: 'thumbnails/999/',
}

// https://developer.hotelbeds.com/documentation/hotels/content-api/use-images/
const hotelbedsSizes = {
  thumbnail: 'medium/',
  large: 'bigger/',
  xlarge: 'xl/',
  og: 'xxl/',
  max: 'xxl/',
}

const cloudinarySizes = {
  thumbnail: ',w_400/',
  large: ',w_800/',
  xlarge: ',w_1024/',
  og: ',w_1920/',
  max: ',w_1920/',
}

const giataSizes = {
  thumbnail: 400,
  large: 576,
  xlarge: 801,
  og: 1400,
  max: 1400,
}

const expediaSizes = {
  thumbnail: '_b',
  large: '_z',
  xlarge: '_z',
  og: '',
  max: '',
}

export const providerSizes = {
  gptravel: gptravelSizes,
  hotelbeds: hotelbedsSizes,
  cloudinary: cloudinarySizes,
  giata: giataSizes,
  expedia: expediaSizes
}

export const supplierURLs = {
  hotelbeds: 'https://photos.hotelbeds.com/giata/[SIZE][PATH]',
  gptravel: 'https://gptravel.rolint.se/gptour/[SIZE][PATH]',
  cloudinary: cloudinaryBaseUri + '/image/upload/f_auto,q_auto,c_limit[SIZE][PATH]',
  opengds: cloudinaryBaseUri + '/image/upload/f_auto,q_auto,c_limit[SIZE][PATH]',
  expedia: 'https://i.travelapi.com/[PATH][SIZE].jpg',
  giata: 'https://api.rolfsbuss.se/giata-image/[SIZE]/[PATH]',
  url: '[PATH]'
}

export function getProvider (image) {
  if (image.match(/gptravel/)) {
    return 'gptravel'
  } else if (image.match(/hotelbeds/)) {
    return 'hotelbeds'
  } else if (image.match(/cloudinary/)) {
    return 'cloudinary'
  } else if (image.match(/travelapi/)) {
    return 'expedia'
  } else if (image.match(/rolfsbuss/)) {
    return 'giata'
  }
}

export function getSize (image) {
  const provider = getProvider(image)
  return Object.values(providerSizes[provider] || {}).find(size => image.includes(`/${size}`)) || ''
}

export function getProviderSize (image, size = 'thumbnail') {
  if (!image) {
    return ''
  }

  if (Array.isArray(image)) {
    return image[0]
  }

  const provider = getProvider(image)

  switch (provider) {
    case 'gptravel':
      return gptravelSizes[size] || ''
    case 'hotelbeds':
      return hotelbedsSizes[size] || ''
    case 'cloudinary':
      return cloudinarySizes[size] || ''
    case 'expedia':
      return expediaSizes[size] || ''
    case 'giata':
      return giataSizes[size] || ''
  }
}
//TODO: check for dubble setup if mixin is used
export default defineNuxtComponent({

  data () {
    return {
      maxNHotelImages: MAX_N_HOTEL_IMAGES
    }
  },
  computed: {
    ...mapState(useLocaleStore, ['isCharter'])
  },

  methods: {
    // Return image size according to ref or window width
    autoImageSize ($ref = null, listAdjustment = 0, widthAdjustmentFactor = 1) {
      let width = 0

      if (process.browser) {
        width = ($ref?.offsetWidth || window.innerWidth || 0) * widthAdjustmentFactor
      }

      const sizes = [
        'thumbnail',
        'large',
        'xlarge',
        'og'
      ]

      let index
      /* if (width < 576) {
        return ''
      } else */
      if (width < 769) {
        index = 0
      } else if (width < 1201) {
        index = 1
      } else {
        index = 2
      }

      const adjustedIndex = index + listAdjustment
      if (adjustedIndex < 0) {
        return sizes[0]
      } else if (adjustedIndex >= sizes.length) {
        return sizes[sizes.length - 1]
      }

      return sizes[adjustedIndex]
    },

    findLowestSize (image, size = 'thumbnail') {
      if (Array.isArray(image)) {
        const lowestSize = giataSizes[size]
        return (
          image.find(i => i?.maxwidth >= lowestSize)
          || image[image.length - 1]
        ).href
      }

      return image || ''
    },

    imageSize (image, size = 'thumbnail', forceJPG = null) {
      if (Array.isArray(image)) {
        return this.findLowestSize(image, size)
      }

      if (forceJPG) {
        image = (image || '').replace('f_auto', 'f_jpg') || ''
      }

      return (image || '').replace('[SIZE]', size ? `${getProviderSize(image, size)}` : '') || ''
    },

    imageSrcSetSizes (image, sizes = {}) {
      return ['thumbnail', 'large', 'xlarge', 'og', 'max']
        .filter(x => sizes[x])
        .map((key) => {
          const size = sizes[key] || ''
          const src = this.findLowestSize(image, size).replace('[SIZE]', getProviderSize(image, key))

          return getProviderSize(image, key) ? `${src} ${size}` : null
        })
        .filter(x => !!x)
        .join(',\n')
    },

    // In case of a failed image, try and load the smaller version.
    smallerImageSize (e) {
      const currentImageSize = getSize(e.target.src)
      const targetImageSize = getProviderSize(e.target.src, 'thumbnail')

      // If already thumbnail
      if (currentImageSize === targetImageSize) {
        return this.errorInSrcSet(e)
      }

      e.target.src = e.target.src.replace(currentImageSize, targetImageSize)
      e.target.srcset = ''
    },

    localeAwareDefaultImage (useHero = false) {

      const hero = isCharter ? SOLRESOR_FALLBACK_HERO_ID : ROLFS_FALLBACK_IMAGE_ID
      const logo = isCharter ? SOLRESOR_FALLBACK_IMAGE : ROLFS_FALLBACK_IMAGE

      return useHero ? hero : logo
    },

    errorInSrcSet (e) {
      const srcset = e.target.srcset || e.target.getAttribute('data-srcset') || ''
      const src = e.target.src
      const sizes = srcset.split(',\n').map(x => x.split(' ')[0])

      let i = sizes.indexOf(src) + 1
      if (e.target.srcset !== '') {
        i = 0
        e.target.setAttribute('data-srcset', srcset)
        e.target.removeAttribute('srcset')
      } else if (src === '') {
        return
      } else if (i >= sizes.length - 1) {
        e.target.parentElement.className += ' --fallback-image'
        e.target.src = this.localeAwareDefaultImage()
        return
      }

      e.target.src = sizes[i]
    }
  }
})
